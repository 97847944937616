import {Map, List} from 'immutable'
import moment from 'moment'

import {vaultActivityText, deputyPermissionActivityText, deputyActivityText} from './reactTools'

export const itemNameOrCount = activity => (activity.get('item-count') > 1 ? `${activity.get('item-count')}` : `${activity.get('item-names').first()}`)

export const writeAccessActivityText = ({activity, client}) => {
  switch (activity.get('status')) {
    case 'client_granted_write_permissions':
      return `added sections of ${client.get('pronoun')} Everplan you can edit.`
    case 'client_removed_write_permissions':
      return `updated your request to edit ${client.get('pronoun')} Everplan.`
    default:
      return `declined your request to edit ${client.get('pronoun')} Everplan.`
  }
}

export const humanizedData = ({client, activity}) => {
  switch (activity.get('type')) {
    case 'login':
      return 'logged in.'
    case 'invite':
      return `${activity.get('status')} your invite.`
    case 'vault':
      return vaultActivityText({activity, client})
    case 'write-access':
      return writeAccessActivityText({activity, client})
    case 'deputy':
      return deputyActivityText({activity, client})
    case 'deputy-permission':
      return deputyPermissionActivityText({client, activity})
    default:
      return
  }
}

export const activityType = activity => (activity.get('type') === 'deputy' && activity.get('requested-pro-everplan') ? 'network-lead' : activity.get('type'))

export const humanizedVaultActivity = ({activity, elementItemMap, client}) => {
  const itemNames = activity.get('element-ids').map(elementId => elementItemMap.getIn([elementId, 'header']))
  const itemCount = itemNames.toSet().size

  return humanizedData({client, activity: activity.merge(Map({'item-names': itemNames, 'item-count': itemCount}))})
}

export const humanizedDeputyPermissionActivity = ({activity, client, items}) => {
  const itemNames = activity.get('item-ids').map(itemId => items.find(item => item.get('id') === itemId).get('header'))
  const itemCount = itemNames.toSet().size

  return humanizedData({client, activity: activity.merge(Map({'item-names': itemNames, 'item-count': itemCount}))})
}

export const excludeLoginActivity = ({activities}) => {
  const sortedActivities = activities.sortBy(activity => new Date(activity.get('timestamp'))).reverse()

  return (
    sortedActivities.getIn([0, 'type']) === 'login' &&
    moment(sortedActivities.getIn([0, 'timestamp'])).diff(sortedActivities.getIn([1, 'timestamp']), 'days') === 0
  )
}

export const hasDeletedElements = ({activity, elementItemMap}) => {
  if (activity.get('type') === 'vault') {
    const elementIds = elementItemMap.keySeq()
    const intersection = activity.get('element-ids').filter(elementId => elementIds.includes(elementId))

    return intersection.size !== activity.get('element-ids').size
  } else { return false }
}

export const humanizedMostRecentActivity = ({client, elementItemMap, items = List()}) => {
  let activities = client.get('activity').filterNot(data => hasDeletedElements({activity: data, elementItemMap}))

  if (activities.size > 1 && excludeLoginActivity({activities}))
    activities = activities.filterNot(data => data.get('type') === 'login')

  const activity = activities.sortBy(data => new Date(data.get('timestamp'))).last()
  const type = activityType(activity)
  const base = {
    ...(type === 'network-lead' && {'deputy-id': activity.get('id')}),
    type,
    timestamp: activity.get('timestamp'),
    id: client.get('id'),
    name: client.get('name'),
    pronoun: client.get('pronoun'),
    status: activity.get('status')
  }

  if (activity.get('type') === 'vault')
    return Map({...base, text: humanizedVaultActivity({activity, client, elementItemMap})})
  else if (activity.get('type') === 'deputy-permission')
    return Map({...base, text: humanizedDeputyPermissionActivity({activity, client, items})})
  else
    return Map({...base, text: humanizedData({client, activity})})
}

export const transformActivities = ({activities, elementItemMap, items}) => activities
  .map(activity => humanizedMostRecentActivity({client: activity, elementItemMap, items}))
  .sortBy(activity => new Date(activity.get('timestamp'))).reverse()

export const activityButtonText = activity => {
  if (activity.get('type') === 'network-lead')
    return 'Add Client'
  else
    return 'View'
}

export const flattenClientActivities = activities => activities.reduce(
  (accumulator, clientActivity) => accumulator.concat(clientActivity.get('activity')),
  List()
)

export * from './reactTools'
